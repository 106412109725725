@import "bootstrap.css";

html,
body {
	height : 100%;
}

#root {
	display          : flex;
	align-items      : center;
	padding-top      : 40px;
	padding-bottom   : 40px;
	background-color : #f5f5f5;
}

.form-signin {
	width     : 100%;
	max-width : 550px;
	padding   : 25px;
	margin    : auto;
}

.form-signin .checkbox {
	font-weight : 400;
}

.form-signin .form-floating:focus-within {
	z-index : 2;
}
